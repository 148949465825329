/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import 'bs-stepper/dist/css/bs-stepper.min.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* COLORS */

:root {
    --border-red: #e63f29;
    --font-color-black:  #343849;
}

::-webkit-scrollbar { 
    width: 0.75rem; 
   
} 
::-webkit-scrollbar-track { 
    background: #FFFFFF; 
    border-radius: 5px;
} 
::-webkit-scrollbar-thumb { 
 /* background: #6649b8;  */
    background: #ff7e42;
    border-radius: 5px;
}

span {
    color: var(--font-color-black);
}

* {
    font-family: 'Poppins', sans-serif;
}

.money{
    font-family: "Varela Round";
    font-weight: bold;    
}    

h1, h2, h3, h4 {
    font-weight: 700 !important;    
}

.border-red {
    border-color: var(--border-red) !important;
}

.text-red {
    color: var(--border-red) !important;
}

/* GENERAL PAGE STYLES */
/*  USE FOR HEADERS AND BODY OF THE PAGES */
.ds-title {
    margin-left: 2vw;
    margin-top: 2vh;
}

.ds-title > h1{
   margin-bottom: 0 !important;
}

.ds-title > br{
   display: none;
 }

.ds-body {
   height: 100%;
   margin-left: 2vw;
   margin-right: 2vw;
   margin-top:3vh;

}

.ds-body-no-height {
    width: 80vw;
    margin-left: 2vw;
    margin-top:3vh
}

.ds-body-max-height-scrollbar {
    width: 97%;
    max-height: 87vh;;
    margin-left: 2vw;
    margin-top: 3vh;
    overflow-y: auto;
}

/* SCROLLBAR STYLES */

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* FLEX STYLES */

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.centered {
    justify-content: center;
    align-items: center;
}

.left-aligned {
    justify-content: flex-start;
    align-items: center;
}

.right-aligned {
    justify-content: flex-end;
    align-items: center;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-between {
    justify-content: space-between;
}


/* GRID STYLES */ 

.justify-self-end {
    justify-self: flex-end;
}

/* BUTTON STYLES */

.button-circle {
    border-radius: 50%;
}

/*  BORDER STYLES  */

.border-5 {
    border-radius: 5px;
}

.border-10 {
    border-radius: 10px;
}

.border-15 {
    border-radius: 15px;
}

.border-20 {
    border-radius: 20px;
}

.borderRed {
    border-color: var(--border-red);
}

.border-dashed-light {
    border-width:3px;  
    border-style:dashed;
    border-color: #e9e9e9;
}

/* GENERAL HOVER STYLES */

.shadowbox div:hover {
    -webkit-box-shadow: 0px 0px 10px 5px #ebebeb;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px#ebebeb;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px #ebebeb;
}

.shadowframe:hover {
    -webkit-box-shadow: 0px 0px 10px 5px #ebebeb !important;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px#ebebeb !important;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px #ebebeb !important;
}

.shadowframeblue:hover {
    -webkit-box-shadow: 0px 0px 10px 5px #e8ebff !important;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px #e8ebff  !important;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px #e8ebff   !important;
}

.shadowframered:hover {
    -webkit-box-shadow: 0px 0px 10px 5px #ffb9b9 !important;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px #ffb9b9  !important;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px #ffb9b9   !important;
}

.shadowframeorange:hover {
    -webkit-box-shadow: 0px 0px 10px 5px #ffe9df !important;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px #ffe3d6 !important;  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px #ffe3d6  !important;
}

.hoverdelete:hover {
   border: var(--border-red);
   background-color: var(--border-red);
   opacity: 100% !important;
}

.hoverblue:hover  {
    color: #1b1be2 !important;
    cursor: pointer;
}

.hoverlightblue:hover  {
    color: #5252ff !important;
    cursor: pointer;
}

.hoverlightred:hover  {
    color: #fa4040 !important;
    cursor: pointer;
}

.hoverlightbluebg:hover  {
    background-color: #a1c7ff !important;
    cursor: pointer;
}

.hoverlightorangebg:hover  {
    background-color: #ffba9a !important;
    cursor: pointer;
}

.hoverred:hover  {
    color: var(--border-red);
    cursor: pointer;
}

.cursorpointer:hover {
    cursor: pointer;
}

 /* FONT STYLES */
 .font-80 {
    font-size: 80%;
}

 .font-90 {
    font-size: 90%;
}

 .font-95 {
    font-size: 95%;
}

 .font-100 {
     font-size: 100%;
 }

 .font-110 {
    font-size: 110%;
}

.font-120 {
    font-size: 120%;
}

.font-130 {
    font-size: 130%;
}

.font-150 {
    font-size: 150%;
}

.font-200 {
    font-size: 200%;
}

.bold {
    font-weight: bold;
}

.font-w-550 {
    font-weight: 550;
}

.font-w-600 {
    font-weight: 600;
}

.font-w-700 {
    font-weight: 700;
}

/* TITLE OPACITY */

.t-opacity-8 {
    opacity: 0.8;
}

/* MARGIN STYLES */

.margin-right-10px {
    margin-right: 10px
}

.margin-bottom-1vh {
    margin-bottom: 1vh;
}

.margin-bottom-2vh {
    margin-bottom: 2vh;
}

.margin-hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.red-requiement {
    /* background-color: red; */
    color: #ff1313;
}

.green-requirement {
    /* background-color: green; */
    color: #009600;
}

.red-background-requiement {
    background-color: #e24040;
}

.green-background-requiement {
    background-color:  #009600;
}

@media (min-width: 1200px) {
    .xxl > .modal-dialog {
        max-width: 80vw !important;
    }
}

.line-div {
    height: 1px;
    background-color: #181818;
    width: 100%;
    margin-bottom: 1vh;
}

/* Styling Checkbox Starts */
.checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    clear: both;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border-radius: 5px;
  	transition: all 0.3s ease-out;
  	-webkit-transition: all 0.3s ease-out;
  	-moz-transition: all 0.3s ease-out;
  	-ms-transition: all 0.3s ease-out;
  	-o-transition: all 0.3s ease-out;
    border: 2px solid  #e9e9e9;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: 2px solid #e9e9e9;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
  	transition: all 0.3s ease-out;
  	-webkit-transition: all 0.3s ease-out;
  	-moz-transition: all 0.3s ease-out;
  	-ms-transition: all 0.3s ease-out;
  	-o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #009BFF;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}



/* For Ripple Effect */
.checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #009BFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);	
}

.checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
  	-webkit-transition: all 0.3s ease-out;
  	-moz-transition: all 0.3s ease-out;
  	-ms-transition: all 0.3s ease-out;
  	-o-transition: all 0.3s ease-out;
}

.popover{
    max-width: fit-content !important; 
}
